<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">数字规划研究分院</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">数字规划研究分院由信息研究所、详细规划三所、专项规划二所、国土利用所组成，是一支以国土空间规划数字转型为目标的综合型规划编制与创新研究团队。着力推进数字技术与国土空间规划、自然资源开发保护、城乡建设等业务的深度融合，打造具有行业竞争力和创新驱动力的数字规划核心技术集群。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;"><b>院长：王欣</b></span> 
        </p>
        <div style="display: inline-block;text-align:center;width: 24%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/信息研究所.jpeg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">信息研究所</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 24%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/详细规划三所.jpeg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">详细规划三所</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 24%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/专项规划二所.jpeg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">专项规划二所</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 24%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/国土利用所.jpeg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">国土利用所</div> 
        </div>
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>